<script setup lang="ts">
import {Images} from "@spoferan/spoferan-ts-core";
import {useIndexStore} from "./store";

const {$image, $analytics} = useNuxtApp();
const route = useRoute();
const config = useRuntimeConfig();
const {t} = useI18n();
const indexStore = useIndexStore();

const i18nHead = useLocaleHead({
	addSeoAttributes: true
})

const title = t('view.index.title');
const description = t('view.index.description');
const author = 'SPOFERAN GmbH';

function checkConsent() {
	const cmpData = __cmp('getCMPData');

	// No consent available yet
	if (!cmpData) {
		return;
	}

	const vendorConsents = cmpData.vendorConsents;
	if (vendorConsents['s26']) {
		$analytics.enable();
	}
}

onMounted(() => {
	if ( typeof __cmp === 'function' ) {
		checkConsent();
		__cmp("addEventListener",["consent",checkConsent,false],null);
	} else {
		let tries = 0;
		let interval = setInterval(() =>  {
			if (typeof __cmp === 'function') {
				clearInterval(interval);
				__cmp("addEventListener",["consent",checkConsent,false],null);
			} else {
				tries++;
				if (tries > 5) {
					clearInterval(interval);
				}
			}
		}, 3000);
	}
});

// This will be reactive even you change title/description above
useHead({
	htmlAttrs: {
		lang: i18nHead.value.htmlAttrs!.lang
	},

	title: title,

	meta: [
		{charset: 'utf-8'},
		{name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0 maximum-scale=1.0'},
		{name: 'description', content: description},
		{name: 'author', content: author},
		{name: 'theme-color', content: '#ffffff'}, // Neutral white as we have specific page colors for events, clubs, etc.
		{name: 'google-site-verification', content: 'vAFTCYIiotczjt0TFnP8udVtJ5OKv4dcE8bBlHzulaA'},

		// Display native app banner
		{name: 'apple-itunes-app', content: `app-id=1546584648, app-argument=${route.fullPath}`},

		{
			'property': 'og:title',
			'content': title,
		},
		{
			'property': 'og:type',
			'content': 'website',
		},
		{
			'property': 'og:site_name',
			'content': 'SPOFERAN'
		},
		{
			'property': 'og:description',
			'content': description,
		},
		{
			'property': 'og:image:url',
			'content': config.public.storageUrl + '/images/spoferan-feature-image.jpg',
		},

		// Fix for IE 11 rendering issues
		{
			'http-equiv': 'X-UA-Compatible',
			'content': 'IE=edge'
		},

		// Activate iOS PWA Splash Screen
		{name: 'apple-mobile-web-app-capable', content: 'yes'},

		...(i18nHead.value.meta || [])
	],
	link: [
		{rel: 'shortcut icon', type: 'image/x-icon', href: '/images/favicons/favicon.ico'},
		{rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/images/favicons/apple-touch-icon.png'},
		{rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/favicons/favicon-32x32.png'},
		{rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/favicons/favicon-16x16.png'},
		{rel: 'mask-icon', type: 'image/png', color: '#008200', href: '/images/favicons/safari-pinned-tab.svg'},

		// We want to set explicit canonical links in the pages
		...(i18nHead.value.link || []).filter(linkItem => linkItem.rel !== 'canonical')
	],
	script: [
		{
			src: '/scripts/consent-manager.js',
		}
	]
});

useSchemaOrg([
	defineOrganization({
		name: author,
		logo: $image.format(Images.logo.path)
	}),
	defineWebSite({
		name: title,
		description: description,
		inLanguage: i18nHead.value.htmlAttrs!.lang,
		potentialAction: [
			defineSearchAction({
				target: '/search?search={search_term_string}'
			})
		]
	})
])
</script>

<template>
	<app>
		<NuxtLayout>
			<VitePwaManifest/>
			<NuxtPage/>

			<client-only>
				<lang-modal v-model="indexStore.langModal"/>
			</client-only>
		</NuxtLayout>
	</app>
</template>
